@font-face {
font-family: '__AmsterdamSans_e7b23c';
src: url(/_next/static/media/319efd4111e5c23d-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__AmsterdamSans_e7b23c';
src: url(/_next/static/media/63dc0c749c9ab14b-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__AmsterdamSans_e7b23c';
src: url(/_next/static/media/56c6f2eaad577d9b-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__AmsterdamSans_Fallback_e7b23c';src: local("Arial");ascent-override: 95.76%;descent-override: 31.27%;line-gap-override: 0.00%;size-adjust: 102.34%
}.__className_e7b23c {font-family: '__AmsterdamSans_e7b23c', '__AmsterdamSans_Fallback_e7b23c', arial, sans-serif
}

